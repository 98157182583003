import React, { useEffect, useState } from "react";
import { DocumentTemplateProps } from "./types";
import LinearProgress from "@material-ui/core/LinearProgress";
import TrainingDocumentTemplate from "./TrainingDocumentTemplate";
import ProductionOrderDocumentTemplate from "./ProductionOrderDocumentTemplate";
import { useHistory } from "react-router-dom";
import { ProductionOrderLookupResult } from "../../core/system/production-orders";
import { TrainingDocument } from "../../core/system/litmon";
import { lookupDocument } from "../../core/system/documents";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";
import PublicProductionOrderTemplate from "./ProductionOrderDocumentTemplate/PublicProductionOrderTemplate";
import { NoPermissionsTemplate } from "../LicensePlateTemplate/PublicLicensePlateTemplate";
import useNotify from "../../core/hooks/use-notify";
import { Box, Typography } from "@material-ui/core";

const NotFoundError = ({ message }: { message: string }) => {
	return (
		<Box pt={10} textAlign="center">
			<Typography variant="body1">{message}</Typography>
		</Box>
	);
};

const DocumentTemplate: React.FC<DocumentTemplateProps> = ({ docid }) => {
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const [lookupResult, setLookupResult] = useState<
		ProductionOrderLookupResult | TrainingDocument | null
	>(null);
	const authenticated = !!useAuthentication()[0];
	const notify = useNotify();

	useEffect(() => {
		const timer = setTimeout(async () => {
			try {
				setLoading(true);

				const result = await lookupDocument(docid);

				// Temporary check for legacy format documents
				if (!result.pick_type) throw new Error("Document not found.");

				if (result.redirect_url && !authenticated) {
					const redirectUrl = result.redirect_url.startsWith("http")
						? result.redirect_url
						: "https://" + result.redirect_url;
					window.location.replace(redirectUrl);
				} else {
					setLookupResult(result);
				}
			} catch (err) {
				// TODO: handle error properly
				console.log(err as Error);
			} finally {
				setLoading(false);
			}
		}, 100); // Debounce by 100ms to allow state to stabilize

		return () => clearTimeout(timer); // Clean up the timer
	}, [authenticated, docid, history, notify]);

	if (loading) return <LinearProgress />;

	if (!lookupResult) return <NotFoundError message="Document not found." />;

	if (!lookupResult.pick_type)
		return <NotFoundError message="Missing document type." />;

	switch (lookupResult.pick_type.toUpperCase()) {
		case "PRODUCTIONORDER":
			return !authenticated &&
				((lookupResult?.public_view_permissions || 0) & 128) === 128 ? (
				<PublicProductionOrderTemplate orderId={lookupResult.id} />
			) : !authenticated ? (
				<NoPermissionsTemplate />
			) : (
				<ProductionOrderDocumentTemplate orderId={lookupResult.id} />
			);
		case "TRAINING":
			return (
				<TrainingDocumentTemplate
					trainingDocument={lookupResult as TrainingDocument}
				/>
			);
		default:
			return <>Unknown document type "{lookupResult.pick_type}".</>;
	}
};

export default DocumentTemplate;
