import moment, { MomentInput } from "moment-timezone";

// function to open a new url in a new tab
export const openInNewTab = (url: string) => {
	if (typeof window === "object") window?.open(url, "_blank");
};

// function to get baseurl : @params: {isOrderTrack}
export const getBaseUrl = () => {
	return process.env.NODE_ENV === "development"
		? window.location.hostname
		: window.location.href.includes("dev")
		? `https://dev.3hd.us/`
		: `https://3hd.us/`;
};

export function isObjectEmpty(obj: object): boolean {
	return Object.keys(obj).length === 0;
}

export const IS_DEV_MODE =
	process.env.NODE_ENV === "development" ||
	window.location.href.includes("dev.3hd.us") ||
	window.location.href.includes("moment-");

export const generateThumbnailFromVideoFile = (
	videoFile: File
): Promise<File> => {
	return new Promise((resolve, reject) => {
		const video = document.createElement("video");
		const url = URL.createObjectURL(videoFile);
		video.src = url;
		video.muted = true; // Avoid autoplay issues
		video.playsInline = true; // For mobile compatibility

		video.addEventListener("loadeddata", () => {
			video.currentTime = video.duration / 2; // Seek to the middle of the video
		});

		video.addEventListener("seeked", () => {
			const canvas = document.createElement("canvas");
			canvas.width = video.videoWidth; // Match video dimensions
			canvas.height = video.videoHeight;
			const ctx = canvas.getContext("2d", { willReadFrequently: true });

			if (ctx) {
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob(
					blob => {
						if (blob) {
							const file = new File([blob], "thumbnail.jpg", { type: "image/jpeg" });
							resolve(file);
						} else {
							reject(new Error("Failed to create blob from canvas"));
						}
					},
					"image/jpeg",
					0.9
				);
			} else {
				reject(new Error("Failed to get canvas context"));
			}

			URL.revokeObjectURL(url); // Clean up
		});

		video.addEventListener("error", err => {
			reject(new Error(`Failed to load video: ${err.message}`));
		});
	});
};

export function parseStringToArray(input: string | any[]): any[] {
	// If input is already an array, return it directly
	if (Array.isArray(input)) {
		return input;
	}

	// Normalize the input by removing surrounding quotes if present
	if (
		typeof input === "string" &&
		input.startsWith('"') &&
		input.endsWith('"')
	) {
		input = input.slice(1, -1);
	}

	// Parse the normalized string as JSON
	try {
		const parsedArray = JSON.parse(input);
		if (Array.isArray(parsedArray)) {
			return parsedArray;
		} else {
			throw new Error("Parsed value is not an array");
		}
	} catch (error) {
		console.error("Failed to parse input:", (error as Error).message);
		return [];
	}
}

export const parseTimeStamp = (time: MomentInput) =>
	moment.utc(time).tz(moment.tz.guess());
