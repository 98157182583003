import React, { useEffect, useState } from "react";
import { PersonTemplateProps } from "./types";
import {
	lookupPerson,
	getPerson,
	Person,
	getUserLogs,
	IUserLogType,
} from "../../core/system/persons";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useLocation, useHistory, Link as RouterLink } from "react-router-dom";
import { useAuthentication } from "../../core/providers/AuthenticationProvider";
import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { GetApp } from "@material-ui/icons";
import DataGrid from "../../core/components/DataGrid";
import { parseTimeStamp } from "../../core/system/utils";
import DateTag from "../../core/components/DateTag";
import dataToCsv from "../../core/system/utils/data-to-csv";
import moment from "moment";

const columns = [
	{
		field: "stringNote",
		headerName: "Memo",
	},
	{
		field: "page",
		headerName: "Page URL",
		renderCell: (row: IUserLogType) => {
			return (
				<Link href={row.page} target="_BLANK">
					{row.page}
				</Link>
			);
		},
	},
	{
		field: "created_at",
		headerName: "Created At",
		valueGetter: (data: IUserLogType) =>
			parseTimeStamp(data.created_at).valueOf(),
		renderCell: (data: IUserLogType) => (
			<DateTag
				date={data?.created_at}
				dateInputType="utc"
				initialMode="since-today"
			/>
		),
	},
];

const PersonTemplate: React.FC<PersonTemplateProps> = ({ personId }) => {
	const currentLocation = useLocation();
	const history = useHistory();
	const [currentUser] = useAuthentication();
	const authenticated = currentUser !== null;
	const [loading, setLoading] = useState(false);
	const [person, setPerson] = useState<Person | null>(null);
	const [userLogs, setUserLogs] = useState<IUserLogType[] | null>(null);

	useEffect(() => {
		(async () => {
			try {
				setLoading(true);
				// Ensure logic only runs after login state stabilizes.
				if (currentUser === null) return;

				const lookupResult = await lookupPerson(personId);

				if (currentUser) {
					const person = await getPerson(lookupResult.id);
					const logsResponse = await getUserLogs(personId);
					setPerson(person);
					setUserLogs(logsResponse);
				} else if (lookupResult.redirect_url) {
					const redirectUrl = lookupResult.redirect_url;
					const url = redirectUrl.startsWith("http")
						? redirectUrl
						: "https://" + redirectUrl;
					window.location.replace(url);
				}
			} catch (err) {
				console.log(err);
				history.replace(`/${personId}/legacy`);
			} finally {
				setLoading(false);
			}
		})();
	}, [currentUser, personId, history]);

	const csvData = userLogs?.map(el => ({
		id: el.id,
		Memo: el.stringNote,
		"Page Url": el.page,
		"Created At": parseTimeStamp(el.created_at).format("DD/MM/YYYY hh:mma"),
	}));

	if (loading)
		return (
			<Box mt={4} textAlign="center">
				<CircularProgress />
			</Box>
		);
	return (
		<Container>
			<Box py={3}>
				{person && (
					<>
						<Paper>
							<Box p={2} bgcolor="grey.200">
								<Grid container spacing={2} alignItems="center">
									<Grid item>
										<Avatar src={""} alt="Person photo" />
									</Grid>
									<Grid item>
										<Typography variant="h6">
											{person?.first_name} {person?.last_name}
										</Typography>
										<Typography variant="subtitle2" color="textSecondary">
											{person?.email}
										</Typography>
									</Grid>
								</Grid>
							</Box>
							<Box p={2}>
								<Typography variant="h6">
									<span className="text-grey">Name:</span>{" "}
									<span>
										{person?.first_name} {person?.last_name}
									</span>
								</Typography>
								<Typography variant="h6">
									<span className="text-grey">Email Address:</span>{" "}
									<a href={`mailto:${person?.email}`}>{person?.email}</a>
								</Typography>
								<Typography variant="h6">
									<span className="text-grey">Phone Number:</span>{" "}
									<span>+{person?.phone}</span>
								</Typography>
							</Box>
						</Paper>

						{userLogs && userLogs?.length > 0 && (
							<Paper style={{ padding: "0.5rem 0", marginTop: "2rem" }}>
								<Box
									style={{
										padding: "1rem",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										flexWrap: "wrap",
										gap: "1rem",
									}}
								>
									<Typography variant="h5">Logs</Typography>
									<Button
										variant="outlined"
										color="primary"
										size="small"
										startIcon={<GetApp />}
										onClick={() =>
											dataToCsv(
												csvData || [],
												`user-logs-${moment().format("DD MMM, YYYY [at] hh:mma")}`
											)
										}
									>
										Download All Logs
									</Button>
								</Box>
								<DataGrid
									rows={userLogs}
									columns={columns}
									noPagination
									windowHeight="calc(100vh - 88px)"
									disableSelectionOnClick
								/>
							</Paper>
						)}
					</>
				)}
				<Divider light />
				{!authenticated && (
					<Box p={2}>
						<Typography align="center" gutterBottom>
							Please login to verify identity.
						</Typography>
						<Box textAlign="center">
							<Link
								underline="none"
								component={RouterLink}
								to={{ pathname: "/login", state: { referrer: currentLocation } }}
							>
								<Button variant="contained" color="primary">
									Login
								</Button>
							</Link>
						</Box>
					</Box>
				)}
			</Box>
		</Container>
	);
};

export default PersonTemplate;
