import { IPageData } from "../../../../IdLookup/LicensePlateTemplate/types";
import { LicensePlateActivityLog } from "../types";
import { parseTimeStamp } from "../../utils";

const styles = `body,
      html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: sans-serif;
        background-color: #f5f5f5;
      }

      img {
        display: inline-block;
        max-width: 100%;
        height: auto;
      }

      a {
        text-decoration: none;
        color: inherit;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .page-container {
        width: 8.27in;
        height: 11.69in;
        padding: 0.5in 0.22in;
        vertical-align: top;
      }

      .overview {
        width: 100%;
        margin-bottom: 20px;
        background-color: #fff;
      }

      .overview__header {
        padding: 16px;
        background-color: rgb(238, 238, 238);
      }

      .overview__link {
        color: rgba(0, 0, 0, 0.54);
      }

      .overview-list {
        padding: 8px 16px;
        width: 100%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .overview-list__item {
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 40px) / 3);
        min-width: 2in;
        margin-bottom: 10px;
      }

      .overview-list__item:not(:last-child) {
        margin-right: 10px;
      }

      .overview-list__name {
        color: rgba(0, 0, 0, 0.54);
        text-transform: uppercase;
      }

      .overview-list__value {
        font-size: 12px;
      }

      .section {
        padding: 8px 0;
        width: 100%;
        background-color: #fff;
      }

      .section__title {
        padding: 0 8px;
      }

      table {
        border-spacing: 0;
        width: 100%;
        max-width: 100%;
      }

      thead {
        background-color: #f5f5f5;
      }

      th {
        padding: 10px 10px;
        text-align: left;
        font-weight: 700;
      }

      tr {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      td {
        padding: 10px 10px;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }

      td:nth-child(4) {
        color: #ec1c24;
      }

      .logs-table td:nth-child(4),
      .logs-table td:nth-child(8) {
        min-width: 1in;
      }`;

const template = (pageData: IPageData, logsPerSheet: number = 30) => {
	const { licensePlateId, productInfo, placeInfo, licensePlate, logs } =
		pageData;

	const isOneSheet = pageData.logs.length <= 12;

	const totalSheets = Math.ceil(pageData.logs.length / logsPerSheet);
	const sheets = Array(totalSheets)
		.fill(undefined)
		.map((_, i) =>
			pageData.logs.slice(i * logsPerSheet, i * logsPerSheet + logsPerSheet)
		);

	const getLogPlace = (log: LicensePlateActivityLog) => {
		if (!log.meta) return "";
		if (!log.meta.location.beacon_id) return log.meta.location.name;

		return `<a href="3hd.us/${log.meta.location.beacon_id}" target="_BLANK">${log.meta.location.name}</a>`;
	};

	const intakeDate = parseTimeStamp(licensePlate.created_at).format(
		"DD MMM, YYYY [at] hh:mma"
	);

	const oneSheetLogs = `<section class="section">
        <h2 class="section__title">Logs</h2>
        <table class="logs-table">
          <thead>
            <tr>
              <th>When</th>
              <th>Activity</th>
              <th>Place</th>
              <th>Who</th>
              <th>Linked</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
          ${logs
											.map(
												log => `<tr>
              <td>${parseTimeStamp(log.created_at).format(
															"DD MMM, YYYY [at] hh:mma"
														)}</td>
              <td>${log.activity}</td>
              <td>${getLogPlace(log)}</td>
              <td>${log.user.first_name}</td>
              <td></td>
              <td>${log.message || ""}</td>
            </tr>`
											)
											.join("")}
          </tbody>
        </table>
      </section>`;

	const fewSheetsLogs = sheets
		.map(
			(sheet, index) => `<div class="page-container">
          <section class="section">
           ${index === 0 ? `<h2 class="section__title">Logs</h2>` : ""}
        <table class="logs-table">
          <thead>
            <tr>
              <th>When</th>
              <th>Activity</th>
              <th>Place</th>
              <th>Who</th>
              <th>Linked</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
           ${sheet
												.map(
													log => `<tr>
              <td>${parseTimeStamp(log.created_at).format(
															"DD MMM, YYYY [at] hh:mma"
														)}</td>
              <td>${log.activity}</td>
              <td>${getLogPlace(log)}</td>
              <td>${log.user.first_name}</td>
              <td></td>
              <td>${log.message || ""}</td>
            </tr>`
												)
												.join("")}
          </tbody>
        </table>
      </section>
        </div>`
		)
		.join("");

	return `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>License Plate Report</title>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/modern-normalize/1.0.0/modern-normalize.min.css"
    />
    <style>${styles}</style>
  </head>
  <body>
    <div class="page-container">
      <section class="overview">
        <div class="overview__header">
            <h2>Product</h2>
            <p class="overview__link">3hd.us/...${licensePlateId.slice(-5)}</p>
        </div>
        <ul class="overview-list">
          <li class="overview-list__item">
            <p class="overview-list__name">Part #</p>
            <p class="overview-list__value"> ${
													productInfo?.part_number || "--"
												} </p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">Description</p>
            <p class="overview-list__value">${
													productInfo?.description || "--"
												}</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">Quantity</p>
            <p class="overview-list__value">${licensePlate.quantity}</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">Currently residing</p>
            <p class="overview-list__value">${placeInfo?.name || "--"}</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">Intake date</p>
            <p class="overview-list__value">${intakeDate}</p>
          </li>
          <li class="overview-list__item">
            <p class="overview-list__name">External SN</p>
            <p class="overview-list__value"> ${
													licensePlate.external_serial_number || "--"
												}</p>
          </li>
        </ul>
      </section>
      ${isOneSheet ? oneSheetLogs : ""}
    </div>
   ${!isOneSheet ? fewSheetsLogs : ""}
  </body>
</html>`;
};

export default template;
