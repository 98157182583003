import { TrainingDocument, TrainingInputData, User } from "./types";
import randomize from "randomatic";
import { subscribe } from "../pubsub";
import html2Pdf, { PdfOptions } from "../utils/html-to-pdf";
import labelTemplate from "./label-templates/training-labels-sheet";
import { generateIds } from "../utils/parse-momenttrack-id";
import certificate_template, {
	ICertificateTemplate,
} from "./label-templates/certificate-template";
import client, { publicApiClient } from "../ordertrack-client";

const authData = {
	org_slug: "",
};
let currUser: Omit<User, "org_slug"> | null = null;

subscribe("update:authentication", ({ data }) => {
	Object.assign(authData, data || { org_slug: "" });
});
subscribe<Omit<User, "org_slug"> | null>("update:curr_person", ({ data }) => {
	currUser = data;
});

const labelPdfOptions: PdfOptions = {
	pdf_mode: "portrait",
	pdf_name: `training-labels-${randomize("a0", 12)}`,
	page_size: "Letter",
	margin_top: "0mm",
	margin_bottom: "0mm",
	margin_left: "0mm",
	margin_right: "0mm",
	disable_smart_shrinking: null,
};

const certificatePdfOptions: PdfOptions = {
	pdf_mode: "landscape",
	pdf_name: `training-labels-${randomize("a0", 12)}`,
	// page_size: "A4",
	margin_top: "0mm",
	margin_bottom: "0mm",
	margin_left: "0mm",
	margin_right: "0mm",
	page_height: "213mm",
	page_width: "297mm",
	disable_smart_shrinking: null,
};

export const generateTrainingQrs = (qty = 30) => {
	const emptyDocs = generateIds("document", qty).map(docid => ({
		docid,
		training_versions: [
			{
				title: "",
			},
		],
		curr_version_index: 0,
	}));

	return html2Pdf(labelTemplate(emptyDocs), {
		...labelPdfOptions,
		pdf_name: `${qty}-training-labels-empty`,
	});
};
export const uid = function () {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
export const generateCertficatePDF = (data: ICertificateTemplate) => {
	return html2Pdf(certificate_template(data), {
		...certificatePdfOptions,
		pdf_name: `litmon_cert_${uid()}`,
	});
};

export const lookupTraining = async (
	docid: string
): Promise<TrainingDocument> => {
	const res = await publicApiClient.get(`lim/${docid}`);
	if (!res) throw new Error("Training not found.");
	else return res?.data?.data;
};

export const saveTraining = async (trainingData: TrainingInputData) => {
	if (!currUser) throw new Error("Not authenticated.");

	const newTraining: TrainingInputData = {
		pick_type: "TRAINING",
		doc_id: trainingData.doc_id,
		email: trainingData.email,
		thumbnail_url: trainingData.thumbnail_url,
		video_url: trainingData.video_url,
		visibility: trainingData.visibility,
		title: trainingData.title,
		instructions: JSON.stringify(trainingData.instructions),
		// there's currently no field on the ui for description but API requires it
		description: "",
	};

	// Patch document to firebase.
	await client.post(`lim`, newTraining);
	// Send label pdf to printer.

	return newTraining;
};
